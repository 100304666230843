import React, { ReactNode } from 'react';
import styled from 'styled-components';
import random from 'random';
import GeoPattern from 'geopattern';
import media from '../../styles/media';
import { theme } from '../../styles/theme';

type PropsType = {
  className?: string;
  color: string;
  children?: ReactNode;
}

const seed = String.fromCharCode(
  ...new Array(10).fill(1).map(() => random.int(1, 255))
);

export default class PatternBackground extends React.Component<PropsType, {}> {
  render() {
    const { children, className, color } = this.props;

    const pattern = GeoPattern.generate(seed, {
      color,
      generator: 'triangles'
    });
    const bgImage = pattern.toDataUrl();


    return (
      <Image bgImage={bgImage} className={className}>
        <ImageOverlay>
          {children}
        </ImageOverlay>
      </Image>
    );
  }
}

const Image = styled.div<{ bgImage: string }>`
  display: flex;
  flex-grow: 1;
  background-image: ${p => p.bgImage};
  // filter: blur(4px);
  transition: background-image ${theme.transitions.medium};
`;

const ImageOverlay = styled.div`
  width: 100%;
  height: 100%;
  padding: 1px;
  background-color: rgba(0, 0, 0, 0.35);
  
  @media (${media.max.tablet}) {
    background-color: transparent;
  }
`;
