import { transparentize } from 'polished';
import React from 'react';
import { FaCaretUp } from 'react-icons/all';
import styled from 'styled-components';
import media from '../../styles/media';
import { theme } from '../../styles/theme';
import { BookInfo } from '../../types/types';

type PropsType = {
  book: BookInfo;
  selected: boolean;
  onClick: (slug: string) => void;
}

export default function BookCover({ book, selected = false, onClick }: PropsType) {
  const { title, img, color, slug, year } = book;
  return (
    <BookRoot>
      <ImageWrap bgColor={color} selected={selected}>
        <ClickHandler onClick={() => onClick(slug)}>
          <Image src={img} alt={title} />
          <ImageOverlay className="overlay" color={color}>
            <OverlayTitle>{title}</OverlayTitle>
            <OverlayYear>{year}</OverlayYear>
          </ImageOverlay>
          {selected && <SelectionArrow />}
        </ClickHandler>
      </ImageWrap>
    </BookRoot>
  );
}

const BookRoot = styled.div`
  
`;
const ImageWrap = styled.div<{ bgColor: string; selected: boolean }>`
  color: white;
  outline: ${p => p.selected && `3px solid ${p => p.bgColor}`};
  outline-offset: ${p => p.selected && '3px'};
`;
const ClickHandler = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  
  &:hover {
    .overlay {
      opacity: 1;
    }
  }
`;
const Image = styled.img`
  width: 100%;
  object-fit: contain;
`;
const ImageOverlay = styled.div<{ color: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${p => transparentize(0.25, p.color)};
  transition: opacity ${theme.transitions.medium};
`;
const OverlayTitle = styled.h2`
  font-size: 20px;
  text-align: center;
`;
const OverlayYear = styled.h3`
  font-size: 14px;
  text-align: center;
`;
const SelectionArrow = styled(FaCaretUp)`
  position: absolute;
  bottom: -9px;
  left: 50%;
  font-size: 40px;
  transform: translate(-50%, 50%);
  pointer-events: none;
  
  @media (${media.max.tablet}) {
    display: none;
  }
`;