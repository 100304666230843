import React, { useState } from 'react';
import { FaBookOpen, FaChevronDown, FaChevronRight } from 'react-icons/all';
import { SlideDown } from 'react-slidedown';
import 'react-slidedown/lib/slidedown.css';
import styled  from 'styled-components';
import media from '../../styles/media';
import { spaceChildren } from '../../styles/mixins';
import { theme } from '../../styles/theme';
import { BookInfo } from '../../types/types';
import { IconButton } from '../common/Button';

export default function BookContentBox({ book, selected = false }: {
  book: BookInfo;
  selected?: boolean;
  isMobile?: boolean;
}) {
  return (
    <ContentBox
      color={book.color}
      hideDesktop={!selected}
      hideMobile
    >
      <BookHeading>{book.title}</BookHeading>
      <BookContent book={book} />
    </ContentBox>
  );
}

export function BookContentMobile({ book, initialExpanded = false }: {
  book: BookInfo;
  initialExpanded?: boolean;
}) {
  const [isExpanded, updateExpanded] = useState(initialExpanded);

  return (
    <ContentBox color={book.color} hideDesktop>
      <BookHeadingMobile onClick={() => updateExpanded(prev => !prev)}>
        <span>{book.title}</span>
        <DropdownIcon isExpanded={isExpanded} />
      </BookHeadingMobile>
      <SlideDown closed={!isExpanded}>
        <BookContent book={book} />
      </SlideDown>
    </ContentBox>
  );
}

export const BookContent = ({ book, className, hideYear = false }: {
  book: BookInfo;
  className?: string;
  hideYear?: boolean;
}) => {
  return (
    <div className={className}>
      {!hideYear && <BookYear>Published in {book.year}</BookYear>}
      <div dangerouslySetInnerHTML={{ __html: book.html }}/>
      <IconButton href={book.slug} color={book.color} secondary>
        <span>Learn more</span>
        <FaBookOpen/>
      </IconButton>
    </div>
  );
};

export const ContentBox = styled.article<{
  color: string;
  hideDesktop?: boolean;
  hideMobile?: boolean;
}>`
  transition: border-color ${theme.transitions.medium};
  padding: 8px 16px;
  margin-top: 1em;
  
  @media (${media.min.tablet}) {
    display: ${p => p.hideDesktop && 'none'};
  }
  
  @media (${media.max.tablet}) {
    border: 1px solid ${p => p.color};
    display: ${p => p.hideMobile && 'none'};
  }
`;

const BookHeading = styled.h2`

`;
const BookHeadingMobile = styled(BookHeading)`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${spaceChildren('8px')}
  
  > svg {
    flex-shrink: 0;
  }
`;
const BookYear = styled.h3`
  font-size: 1.5rem;
  margin-top: 0;
`;
const DropdownIcon = styled(
  ({ isExpanded, ...rest }) => <FaChevronRight {...rest} />)`
  transition: transform ${theme.transitions.medium};
  transform: rotate(${p => p.isExpanded ? 90 : 0}deg);
`;