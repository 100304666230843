import React, { Fragment } from 'react';
import styled from 'styled-components';
import media from '../../styles/media';
import { spaceChildren } from '../../styles/mixins';
import { BookInfo } from '../../types/types';
import BookContentBox, { BookContentMobile } from './BookContentBox';
import BookCover from './BookCover';

type PropsType = {
  books: BookInfo[];
  selected: BookInfo;
  updateSelected: (slug: string) => void;
}

export default function BookFeature({ books, selected, updateSelected }: PropsType) {
  return (
    <>
      <BookWrap>
        {books.map((book, i) => (
          <Fragment key={book.slug}>
            <BookCover
              key={`cover-${book.slug}`}
              selected={book.slug === selected.slug}
              onClick={updateSelected}
              book={book}
            />
            <BookContentMobile
              book={book}
              key={`content-${book.slug}`}
              initialExpanded={i === 0}
            />
          </Fragment>
        ))}
      </BookWrap>
      {books.map(book => (
        <BookContentBox
          book={book}
          key={book.slug}
          selected={book.slug === selected.slug}
        />
      ))}
    </>
  );
}


const BookWrap = styled.section`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 16px;
  
  @media (${media.max.tablet}) {
    display: flex;
    flex-direction: column;
    ${spaceChildren('', '12px')};
  }
`;