import { graphql } from 'gatsby';
import React, { useState } from 'react';
import styled from 'styled-components';
import PatternBackground from '../components/common/PatternBackground';
import BookFeature from '../components/home/BookFeature';
import SimpleBook from '../components/home/SimpleBook';
import Layout, { ContentWrap } from '../components/Layout';
import SEO from '../components/SEO';
import book1 from '../images/books/palace-of-tears.jpg';
import book2 from '../images/books/the-opal-dragonfly.jpg';
import book3 from '../images/books/death-in-the-ladies-goddess-club.jpg';
import book4 from '../images/books/phantasmic-detective-agency.jpg';
import media from '../styles/media';
import { theme } from '../styles/theme';
import { BookInfo } from '../types/types';

type PropsType = {
  data: GatsbyTypes.BookListQuery;
}

export default function Index(props: PropsType) {
  const bookData = props.data.allMarkdownRemark.edges;
  const mappedData: Map<string, string> = new Map();
  bookData.forEach(({ node }) => {
    mappedData.set(node.frontmatter.id, node.html);
  });

  const books: BookInfo[] = [
    {
      title: 'Palace of Tears',
      img: book1,
      color: theme.colors.primary,
      slug: 'palace-of-tears',
      year: 2015
    },
    {
      title: 'The Opal Dragonfly',
      img: book2,
      color: '#947549',
      slug: 'the-opal-dragonfly',
      year: 2018
    },
    {
      title: 'Death in the Ladies\' Goddess Club',
      img: book3,
      color: '#161237',
      slug: 'death-in-the-ladies-goddess-club',
      year: 2020
    },
    {
      title: 'The Phantasmic Detective Agency',
      img: book4,
      color: '#4bafe3',
      slug: 'the-phantasmic-detective-agency',
      year: 2020
    }
  ].map((book: BookInfo & { html?: string }) => {
    book.html = mappedData.get(book.slug);
    return book;
  });
  const [lastBook, ...featureBooks] = books.reverse();

  const [selectedSlug, updateSelected] = useState(featureBooks[0].slug);
  const selected = featureBooks.find(book => book.slug === selectedSlug);

  return (
    <Layout fullWidth>
      <SEO title="Home" />
      <Home>
        <BookPattern color={selected.color}>
          <ContentWrap>
            <BookFeature
              books={featureBooks}
              selected={selected}
              updateSelected={updateSelected}
            />
          </ContentWrap>
        </BookPattern>
        <BookPattern color={lastBook.color}>
          <ContentWrap>
            <h3>Also discover Julian's debut children's novel...</h3>
            <SimpleBook book={lastBook} />
          </ContentWrap>
        </BookPattern>
      </Home>
    </Layout>
  );
}

const Home = styled.div`
  color: white;
  
  @media (${media.max.tablet}) {
    color: inherit;
  }
`;

const BookPattern = styled(PatternBackground)`
  @media (${media.max.tablet}) {
    background-image: none;
  }
`;

export const bookListQuery = graphql`
  query BookList {
    allMarkdownRemark(filter: {frontmatter: {postType: {eq: "book"}}}) {
      edges {
        node {
          id
          html
          frontmatter {
            postType
            id
          }
        }
      }
    }
  }
`;
