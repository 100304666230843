import React from 'react';
import styled from 'styled-components';
import media from '../../styles/media';
import { BookInfo } from '../../types/types';
import { BookContent, ContentBox } from './BookContentBox';

export default function SimpleBook({ book }: { book: BookInfo }) {
  return (
    <Root>
      <Cover src={book.img} />
      <ContentBoxStyled color={book.color}>
        <BookTitle>{book.title}</BookTitle>
        <BookContent book={book} hideYear />
      </ContentBoxStyled>
    </Root>
  );
}
const Root = styled.article`
  display: grid;
  grid-template-columns: 300px 1fr;
  grid-column-gap: 32px;
  
  @media (${media.max.tablet}) {
    display: flex;
    flex-direction: column;
  }
`;
const BookTitle = styled.h2`
  margin-top: 0;
  font-size: 1.6rem;
`;
const Cover = styled.img`
  width: 100%;
  object-fit: contain;
`;
const ContentBoxStyled = styled(ContentBox)`
  @media (${media.min.tablet}) {
    margin-top: 0;
  }
`;
